import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo } from 'react';

import LazyHydration from 'components/LazyHydration';

const Card = dynamic(() => import('./Card'));

const FeaturedOfferCard = (props) => (
  <LazyHydration placeholderSize={{ height: 442 }}>
    <Card {...props} />
  </LazyHydration>
);

FeaturedOfferCard.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  cardTitleAs: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  offerComments: PropTypes.number.isRequired,
  offerIsHighlight: PropTypes.bool.isRequired,
  offerImagePriority: PropTypes.bool,
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  offerTitle: PropTypes.string.isRequired,
  storeDomain: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default memo(FeaturedOfferCard);
