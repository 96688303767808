import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

const Margin = ({ children, className }) => (
  <div
    className={twJoin(
      'group/layout relative mx-auto my-0 mb-16 grid w-full lg:w-screen-lg lg:grid-cols-16 xl:w-screen-xl [&:not(:has(>[data-heading=true]))]:pt-4 lg:[&:not(:has(>[data-heading=true]))]:pt-14 [&_*]:font-rubik',
      className
    )}
    data-global-flatbg
  >
    {children}
  </div>
);

Margin.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Margin;
