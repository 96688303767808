import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Heading from 'shopper/components/Heading';

import useModal from 'hooks/useModal';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';

import { useUser } from 'providers/UserProvider';

import OffersOptions from './OffersOptions';

const FilterOffersByCategoriesModal = dynamic(
  () => import('components/Timeline/FilterOffersByCategoriesDialog'),
  { ssr: false }
);

const OffersListHeader = forwardRef(({ gaEventCategory, ...rest }, ref) => {
  const { showModal } = useModal();
  const { runCallbackIfLoggedIn } = useUser();

  const onHideOffersCategoriesLinkClick = (e) => {
    e.preventDefault();

    runCallbackIfLoggedIn(() => {
      sendEvent({
        action: 'show_hide_categories',
        category: gaEventCategory,
      });

      showModal(FilterOffersByCategoriesModal);
    });
  };

  return (
    <div
      className="flex max-w-full flex-col items-start justify-between gap-y-4 lg:flex-row lg:items-center"
      {...rest}
    >
      <Heading size="size3">{placeholder('titles.offers')}</Heading>
      <OffersOptions
        ref={ref}
        gaEventCategory={gaEventCategory}
        onHideOffersLinkClick={onHideOffersCategoriesLinkClick}
      />
    </div>
  );
});

OffersListHeader.displayName = 'OffersListHeader';

OffersListHeader.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default OffersListHeader;
