import PropTypes from 'prop-types';

const Heading = ({ children }) => (
  <header className="w-full px-4 lg:col-span-16 lg:px-0" data-heading>
    {children}
  </header>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Heading;
