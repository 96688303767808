/* eslint-disable no-console */
import Script from 'next/script';

import { parseToBool } from 'lib/string';

const LandingiScript = () => {
  if (!parseToBool(process.env.NEXT_PUBLIC_LANDINGI_ENABLED)) {
    return null;
  }

  return (
    <Script
      id="landingi-last-comments"
      src="https://popups.landingi.com/api/v3/website/install-code?apikey=813f387f-5771-4f16-89e5-6bde16f355b2"
    />
  );
};

export default LandingiScript;
