/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: adequate component to a11y
import PropTypes from 'prop-types';
import { cloneElement, createElement, forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

const SIZES = ['size1', 'size2', 'size3'];
const TYPES = ['primary', 'neutral'];

const SIZES_STYLES = {
  size1: 'py-1 px-4 text-base rounded-5 lg:px-6 lg:py-2',
  size2: 'py-1 px-4 text-xs rounded-5 lg:py-2',
  size3: 'py-0.5 px-2 lg:py-1 lg:px-2 text-xs rounded-4',
};

const ICON_SIZES = {
  size1: 'size2',
  size2: 'size3',
};

const ICON_STYLES = {
  primary: 'text-primary-300 dark:text-primary-300',
  'primary-filled': 'text-neutral-high-100 dark:text-neutral-high-100',
  neutral: 'text-neutral-low-200 dark:text-neutral-high-400',
  'neutral-filled': 'text-neutral-low-100 dark:text-neutral-low-500',
};

const TYPES_STYLES = {
  primary:
    'bg-transparent border-primary-300 text-primary-300 hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-300',
  'primary-filled':
    'bg-primary-300 border-primary-300 text-neutral-high-100 hover:text-neutral-high-100 dark:bg-primary-300 dark:border-primary-300 dark:text-neutral-high-100 dark:hover:text-neutral-high-100',
  neutral:
    'bg-transparent border-neutral-high-300 text-neutral-low-100 hover:text-neutral-low-100 dark:border-neutral-high-400 dark:text-neutral-high-100 hover:dark:text-neutral-high-100',
  'neutral-filled':
    'bg-neutral-high-300 border-neutral-high-300 text-neutral-low-100 hover:text-neutral-low-100 dark:bg-neutral-low-300 dark:border-neutral-low-300 dark:text-neutral-high-100 hover:dark:text-neutral-low-500',
};

const TYPES_HOVER_STYLES = {
  primary:
    'hover:border-primary-200 hover:text-primary-300 dark:hover:border-primary-200 dark:hover:text-primary-300',
  'primary-filled':
    'hover:bg-primary-400 hover:border-primary-400 dark:hover:bg-primary-400 dark:hover:border-primary-400',
  neutral:
    'hover:border-neutral-low-400 hover:text-neutral-low-400 hover:border-neutral-low-400 dark:hover:text-neutral-high-200 dark:hover:border-neutral-high-200',
  'neutral-filled':
    'hover:border-neutral-low-400 hover:text-neutral-low-400 hover:border-neutral-low-400 dark:hover:text-neutral-high-200 dark:hover:border-neutral-high-200',
};

const Tag = forwardRef(
  (
    {
      as = 'div',
      bold = false,
      children = null,
      className,
      clickable = false,
      disabled = false,
      filled = false,
      iconLeft = null,
      iconRight = null,
      size = 'size2',
      type = 'neutral',
      onClick = noop,
      ...rest
    },
    ref
  ) => {
    const formattedType = `${type}${filled ? '-filled' : ''}`;

    const onClickHandler = (e) => {
      if (!clickable) {
        return;
      }

      onClick(e);
    };

    return createElement(
      as,
      {
        ref,
        className: twMerge(
          'flex size-fit select-none items-center justify-center whitespace-pre border border-solid',
          SIZES_STYLES[size],
          TYPES_STYLES[formattedType],
          bold && 'font-bold',
          clickable && !disabled && TYPES_HOVER_STYLES[formattedType],
          clickable && 'cursor-pointer transition-all duration-150 ease-in',
          disabled && 'cursor-not-allowed',
          disabled &&
            'border-transparent bg-neutral-high-200 text-neutral-high-400 dark:text-neutral-high-400',
          className
        ),
        disabled,
        onClick: onClickHandler,
        ...rest,
      },
      <>
        {iconLeft &&
          cloneElement(iconLeft, {
            size: ICON_SIZES[size],
            className: twJoin(
              ICON_STYLES[formattedType],
              disabled && 'text-neutral-high-400 dark:text-neutral-high-400',
              'mr-1'
            ),
          })}
        {children}
        {iconRight &&
          cloneElement(iconRight, {
            size: ICON_SIZES[size],
            className: twJoin(
              ICON_STYLES[formattedType],
              disabled && 'text-neutral-high-400 dark:text-neutral-high-400',
              'ml-1'
            ),
          })}
      </>
    );
  }
);

Tag.displayName = 'Tag';

Tag.propTypes = {
  /**
   * HTML markup to be rendered
   */
  as: PropTypes.node,
  /**
   * Renders a bold text
   */
  bold: PropTypes.bool,
  /**
   * String or `Icon` to be rendered inside component
   */
  children: PropTypes.node,
  /**
   * Renders a `Tag` with clickable behavior
   */
  clickable: PropTypes.bool,
  /**
   * Renders a disabled `Tag`
   */
  disabled: PropTypes.bool,
  /**
   * Renders a `Tag` with filled styles
   */
  filled: PropTypes.bool,
  /**
   * Renders an icon on the left side
   */
  iconLeft: PropTypes.object,
  /**
   * Renders an icon on the right side
   */
  iconRight: PropTypes.object,
  /**
   * Renders a `Tag` with one of the following sizes
   */
  size: PropTypes.oneOf(SIZES),
  /**
   * Renders a `Tag` with different types
   */
  type: PropTypes.oneOf(TYPES),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
};

export default Tag;
