import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const Body = ({ children, className }) => (
  <main
    className={twMerge(
      'col-span-full flex max-w-screen flex-col flex-nowrap px-4 lg:col-span-16 lg:px-0 lg:group-has-[>[data-sidebar=true]]/layout:col-span-12',
      className
    )}
  >
    {children}
  </main>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Body;
