import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';
import Tag from 'shopper/components/Tag/V2';

import { sendEvent } from 'lib/gtag';
import { stripQs } from 'lib/request';

import HOME_OFFERS_LIST_TABS from 'constants/homeOffersListTabs';

const OffersOptions = forwardRef(
  ({ gaEventCategory, onHideOffersLinkClick }, ref) => {
    const router = useRouter();
    const pathname = stripQs(router.asPath);

    return (
      <div
        ref={ref}
        className="-ml-6 flex w-[calc(100%_+_48px)] snap-x gap-x-2 overflow-x-auto overflow-y-hidden px-6 scrollbar-none lg:-ml-0 lg:w-auto lg:px-0"
      >
        {HOME_OFFERS_LIST_TABS.map(({ id, title, iconName, href }) => {
          const isActive = href === pathname;

          return (
            <NextLink
              key={id}
              href={href}
              prefetch={false}
              scroll={false}
              passHref
            >
              <Tag
                as="a"
                className="flex items-center rounded-5 py-2"
                filled={isActive}
                iconLeft={<Icon name={iconName} />}
                size="size2"
                type={isActive ? 'primary' : 'neutral'}
                bold
                clickable
                onClick={() => {
                  sendEvent({
                    action: `sheet_${id}_offers`,
                    category: gaEventCategory,
                  });
                }}
              >
                {title}
              </Tag>
            </NextLink>
          );
        })}
        <div className="relative flex flex-row items-center justify-end lg:mr-px">
          <Button
            aria-label="Abrir modal de ocultar ofertas"
            data-test-selector="open-hide-offers-modal"
            icon={<Icon name="settings" />}
            size="size4"
            type="neutral-ghost"
            onClick={onHideOffersLinkClick}
          />
        </div>
      </div>
    );
  }
);

OffersOptions.displayName = 'OffersOptions';

OffersOptions.propTypes = {
  gaEventCategory: PropTypes.string,
  onHideOffersLinkClick: PropTypes.func.isRequired,
};

export default OffersOptions;
