import Footer from 'components/Footer';
import LazyHydration from 'components/LazyHydration';

const LazyHydratedFooter = () => (
  <LazyHydration placeholderSize={{ height: 1752 }}>
    <Footer />
  </LazyHydration>
);

export default LazyHydratedFooter;
