import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

import LazyHydratedGooglePublisherTagLandscapeAdSlot from 'components/AdSlots/GooglePublisherTagLandscapeAdSlot/LazyHydratedGooglePublisherTagLandscapeAdSlot';
import OfferCard from 'components/Timeline/OfferCard/V2/OfferGridCard';

const OfferCardAd = dynamic(() => import('components/Timeline/OfferCardAd/V2'));

const OfferCardWithAd = ({
  activeGoogleAd,
  featured = false,
  gaEventCategory,
  offer,
  offerImagePriority = false,
}) => (
  <>
    {offer?.cardAds?.length > 0 &&
      offer.cardAds.map(
        (cardAd) =>
          (cardAd.image || cardAd.mobileImage) && (
            <OfferCardAd
              key={cardAd.id}
              gaEventCategory={gaEventCategory}
              {...cardAd}
            />
          )
      )}
    {activeGoogleAd && (
      <div
        className={twJoin(
          'col-start-1 col-end-1 row-start-10 md:col-end-4 md:row-start-4 lg:col-end-5 lg:row-start-3',
          activeGoogleAd?.className
        )}
      >
        <LazyHydratedGooglePublisherTagLandscapeAdSlot
          adId={activeGoogleAd.adId}
          adPath={activeGoogleAd.adPath}
          showAdSlotOnBreakpoints={activeGoogleAd.showAdSlotOnBreakpoints}
        />
      </div>
    )}
    <OfferCard
      featured={featured}
      gaEventCategory={gaEventCategory}
      offerImagePriority={offerImagePriority}
      {...offer}
    />
  </>
);

OfferCardWithAd.propTypes = {
  activeGoogleAd: PropTypes.shape({
    adId: PropTypes.string.isRequired,
    adPath: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    showAdSlotOnBreakpoints: PropTypes.array,
  }),
  gaEventCategory: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    categoryId: PropTypes.number,
    categoryName: PropTypes.string,
    categorySlug: PropTypes.string,
    key: PropTypes.string.isRequired,
    offerComments: PropTypes.number.isRequired,
    offerId: PropTypes.number.isRequired,
    offerIsHighlight: PropTypes.bool.isRequired,
    ratings: PropTypes.shape().isRequired,
    offerOldPrice: PropTypes.number,
    offerPhoto: PropTypes.string.isRequired,
    offerPrice: PropTypes.number.isRequired,
    offerPriceType: PropTypes.string.isRequired,
    offerPublished: PropTypes.string.isRequired,
    offerSlug: PropTypes.string.isRequired,
    offerStatusName: PropTypes.string.isRequired,
    offerUserVisibility: PropTypes.string.isRequired,
    offerTags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ),
    offerTitle: PropTypes.string.isRequired,
    storeDomain: PropTypes.string.isRequired,
    storeId: PropTypes.number,
    storeImage: PropTypes.string,
    storeName: PropTypes.string,
    subcategoryId: PropTypes.number,
    subcategoryName: PropTypes.string,
    subcategorySlug: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    userName: PropTypes.string.isRequired,
    userPhoto: PropTypes.string.isRequired,
    userTypeName: PropTypes.string.isRequired,
    userUsername: PropTypes.string.isRequired,
  }).isRequired,
  offerImagePriority: PropTypes.bool,
};

export default memo(OfferCardWithAd);
