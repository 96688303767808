import dynamic from 'next/dynamic';

import Body from './Body';
import Footer from './Footer';
import Heading from './Heading';
import Margin from './Margin';

const Sidebar = dynamic(() => import('./Sidebar'));

const FlatLayout = {
  Body,
  Footer,
  Heading,
  Margin,
  Sidebar,
};

export default FlatLayout;
