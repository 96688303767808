import { useMutation } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import Icon from 'shopper/components/Icon';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useMediaQuery from 'hooks/useMediaQuery';
import useOffersFilters from 'hooks/useOffersFilters';
import useQueryClient from 'hooks/useQueryClient';
import useToast from 'hooks/useToast';

import { findAll } from 'lib/array';
import { sendEvent } from 'lib/gtag';
import { toFormattedNewOffersCount, toFormattedNewOffersText } from 'lib/offer';
import placeholder from 'lib/placeholder';
import { offerQueryKeys } from 'lib/queryKeys';
import { scrollToElement, updateFavicon, updatePageTitle } from 'lib/utils';

import { useGlobalQueries } from 'providers/GlobalQueriesProvider';

import OfferCardWithAd from './OfferCardWithAd';

import APP from 'constants/app';
import WHATSAPP_GROUP from 'constants/whatsappGroupCampaign';

const OfferCardSkeleton = dynamic(
  () => import('components/Timeline/OfferCard/V2/OfferGridCard/Skeleton'),
  { ssr: false }
);
const SkeletonGroup = dynamic(
  () => import('components/Skeleton/SkeletonGroup'),
  { ssr: false }
);

const ADDITIONAL_SPACE_FROM_TOP_ON_SCROLL_IN_PX = 16;
const SECONDS_TO_FETCH_FOR_NEW_OFFERS = 60;
const GOOGLE_AD_MANAGER_OFFERS_LIST_ADS = [];

const getCardsAdsCountBeforeIndex = (cardsAdsPositions, index) =>
  cardsAdsPositions.filter((cardsAdsPosition) => cardsAdsPosition < index)
    .length;

const getActiveGoogleAdForCurrentOfferIndex = (index, cardsAdsPositions) =>
  GOOGLE_AD_MANAGER_OFFERS_LIST_ADS.find(
    ({ position }) =>
      position - getCardsAdsCountBeforeIndex(cardsAdsPositions, index) === index
  );

const OffersList = ({
  cardsAds,
  gaEventCategory,
  offersFilters = null,
  offersListHeaderRef,
  serverOffers,
}) => {
  const fetchNextPageOffersRef = useRef(null);
  const offersListBeforeRef = useRef(null);
  const { showToast } = useToast();
  const { isLg } = useMediaQuery();
  const queryClient = useQueryClient();
  const { getOffersFilters } = useOffersFilters();
  const { activeOffersListTab } = useGlobalQueries();
  const [fetchNextPageOffersCount, setFetchNextPageOffersCount] = useState(0);
  const [shouldLoadMoreOffers, setShouldLoadMoreOffers] = useState(false);

  const {
    data: offers,
    fetchNextPage: fetchNextOffersPage,
    hasNextPage: hasNextOffersPage,
    isFetching,
    isFetchingNextPage,
    isInitialLoading,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: offerQueryKeys.list({ activeTab: activeOffersListTab.id }),
    queryFn: async (after, { signal }) => {
      const filters = getOffersFilters();

      sendEvent({
        action: `paginate_${fetchNextPageOffersCount + 1}`,
        category: gaEventCategory,
      });

      return activeOffersListTab.fetcher({ after, filters }, { signal });
    },
    /**
     * Saves `cardAd` as a offer prop for perf as every `cardAd` can
     * be at any position at the list, so we would have to do double loops
     * every time this list is rendered otherwise
     */
    select: (data) =>
      data.map((offer, index) => ({
        ...offer,
        cardAds: findAll(cardsAds, (cardAd) => cardAd.position - 1 === index),
      })),
    getPreviousPageParam: (firstOffers) => {
      offersListBeforeRef.current = firstOffers?.before;
    },
    getNextPageParam: (lastOffers) => lastOffers?.after,
    flattenKey: 'offers',
    initialData: serverOffers,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const { mutate: mutateLoadNewOffers } = useMutation({
    mutationFn: (before) =>
      activeOffersListTab.fetcher({
        before,
        filters: offersFilters,
      }),
    onSuccess: (newestOffersPage) => {
      const newestOffersQuantity = newestOffersPage?.offers?.length;

      if (!newestOffersPage?.offers || newestOffersQuantity === 0) {
        return;
      }

      updateFavicon(
        `${APP.LOCAL_IMAGE_PATH}/features/timeline/new-offers-favicon.ico`
      );
      updatePageTitle(
        `(${toFormattedNewOffersCount(
          newestOffersQuantity
        )}) | Promoções e Cupons é no Promobit`
      );

      showToast({
        text: toFormattedNewOffersText(
          toFormattedNewOffersCount(newestOffersQuantity)
        ),
        direction: 'top',
        iconRight: <Icon name="refresh" />,
        type: 'success',
        autoClose: false,
        closeDelay: 200,
        key: 'refresh-offers-timeline',
        onClick: () => {
          queryClient.setInfiniteQueryData(
            offerQueryKeys.list({ activeTab: activeOffersListTab.id }),
            (pages) => [newestOffersPage, ...pages]
          );

          sendEvent({
            category: gaEventCategory,
            action: 'toast_update_offer',
          });
          scrollToElement(
            offersListHeaderRef.current?.offsetTop -
              ADDITIONAL_SPACE_FROM_TOP_ON_SCROLL_IN_PX,
            isLg
          );
          updateFavicon(APP.FAVICON);
          updatePageTitle(placeholder('seo.default.title'));
        },
      });
    },
  });

  useEffect(() => {
    return () => {
      updateFavicon(APP.FAVICON);
      updatePageTitle(placeholder('seo.default.title'));
    };
  }, []);

  const onFetchNextPageOffers = () => {
    fetchNextOffersPage();
    setFetchNextPageOffersCount((prev) => prev + 1);
  };

  useIntersectionObserver({
    enabled: hasNextOffersPage && !isFetching,
    target: fetchNextPageOffersRef,
    rootMargin: '1500px',
    threshold: 0,
    onIntersect: onFetchNextPageOffers,
  });

  useEffect(() => {
    if (shouldLoadMoreOffers) {
      mutateLoadNewOffers(offersListBeforeRef.current);
      setShouldLoadMoreOffers(false);
    }
  }, [shouldLoadMoreOffers]);

  useEffect(() => {
    if (activeOffersListTab.id === 'trend') {
      return;
    }

    const interval = setInterval(() => {
      setShouldLoadMoreOffers(true);
    }, SECONDS_TO_FETCH_FOR_NEW_OFFERS * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchNextPageOffersCount]);

  const cardsAdsPositions = useMemo(
    () => cardsAds.map(({ position }) => position),
    [cardsAds]
  );

  const isOffersListLoading = !!(
    isFetchingNextPage ||
    (isFetching && !isInitialLoading)
  );

  const onWhatsAppCampaignBannerClick = () => {
    sendEvent({
      action: 'whatsapp_group_banner_click',
      category: gaEventCategory,
    });
  };

  return (
    <>
      <div
        className="grid grid-flow-row grid-cols-1 gap-4 lg:grid-cols-4"
        data-test-selector="offers-grid"
      >
        {isSuccess &&
          offers.map((offer, index) => (
            <Fragment key={`${offer.key}-${index}`}>
              {index === 7 && (
                <Anchor
                  className="mx-auto hidden lg:col-start-1 lg:col-end-5 lg:row-start-3 lg:block"
                  href={WHATSAPP_GROUP.DEFAULT_GROUP_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={onWhatsAppCampaignBannerClick}
                >
                  <Image
                    alt="Ilustração de grupos do Promobit no WhatsApp"
                    className="rounded-4"
                    height={90}
                    src={`${APP.LOCAL_IMAGE_PATH}/features/whatsapp/whatsapp_offer_list-v3.png`}
                    width={728}
                  />
                </Anchor>
              )}
              <OfferCardWithAd
                activeGoogleAd={getActiveGoogleAdForCurrentOfferIndex(
                  index,
                  cardsAdsPositions
                )}
                gaEventCategory={gaEventCategory}
                offer={offer}
                offerImagePriority
              />
            </Fragment>
          ))}
        {isOffersListLoading && (
          <SkeletonGroup items={8} skeleton={OfferCardSkeleton} />
        )}
      </div>
      <div ref={fetchNextPageOffersRef} />
    </>
  );
};

OffersList.propTypes = {
  cardsAds: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      isClosable: PropTypes.bool.isRequired,
      mobileImage: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      storeSlug: PropTypes.string,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  offersFilters: PropTypes.shape({
    priceRange: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    store: PropTypes.arrayOf(PropTypes.string),
    venue: PropTypes.string,
  }),
  offersListHeaderRef: PropTypes.shape().isRequired,
  serverOffers: PropTypes.shape({
    after: PropTypes.string.isRequired,
    before: PropTypes.number,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
        categorySlug: PropTypes.string,
        key: PropTypes.string.isRequired,
        offerComments: PropTypes.number.isRequired,
        offerId: PropTypes.number.isRequired,
        offerIsHighlight: PropTypes.bool.isRequired,
        ratings: PropTypes.shape().isRequired,
        offerOldPrice: PropTypes.number,
        offerPhoto: PropTypes.string.isRequired,
        offerPrice: PropTypes.number.isRequired,
        offerPriceType: PropTypes.string.isRequired,
        offerPublished: PropTypes.string.isRequired,
        offerSlug: PropTypes.string.isRequired,
        offerStatusName: PropTypes.string.isRequired,
        offerUserVisibility: PropTypes.string.isRequired,
        offerTags: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
        offerTitle: PropTypes.string.isRequired,
        storeDomain: PropTypes.string.isRequired,
        storeId: PropTypes.number,
        storeImage: PropTypes.string,
        storeName: PropTypes.string,
        subcategoryId: PropTypes.number,
        subcategoryName: PropTypes.string,
        subcategorySlug: PropTypes.string,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userTypeName: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default OffersList;
